/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/86d6d32ab6c2e21d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/07304b90e9b5ca40-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/54f3953b28e50f98-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a40ccebe730e6b34-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/01ec6e01ff4830ae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/34d8d58b783ee6bb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3763ada0a9bb1988-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/787b0654beea4191-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/2c3c1f0838a71cfb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/47bcda1e7b1ac8be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/bed76354f405346d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c70aa70a01eaecff-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/24cfcc12c15b03b9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/29ffcd3df3f1cb91-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3cef106cd115e360-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a0c95037dd5c9387-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e889cd181219a83f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6d6d0e4a023f9f33-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0148c4ca3456ea72-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4184cf5f191b83db-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28cea0425961ab58-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4c759cba7a5f011-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/de555e6bc944c11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/791334aa8b2b679f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a2411df190893074-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/60d74363e715829c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb37c9fe4776ea97-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc381c5935034aa5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f565fad065ab4cf3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f9946f06d70a1b5b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1f5f89450ecd78c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ebcf93a611f94ec0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec50b434b597bc61-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6be22a85bef54f08-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9491f41002d34397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/afba6ba6bf9157e8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/be843c5b0882ad66-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/67aea609c6135b3b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/09b62d668edb05d1-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/10446295ae7f2657-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b6ecfd81ed9f83c9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/08404bcfb1dae67a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_9bf800';src: local("Arial");ascent-override: 101.32%;descent-override: 27.18%;line-gap-override: 0.00%;size-adjust: 101.17%
}.__className_9bf800 {font-family: '__IBM_Plex_Sans_9bf800', '__IBM_Plex_Sans_Fallback_9bf800';font-style: normal
}.__variable_9bf800 {--font-ibm-plex-sans: '__IBM_Plex_Sans_9bf800', '__IBM_Plex_Sans_Fallback_9bf800'
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/16897c075393297e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 250;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/f26efda9679a5be2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 250;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/83ffe2487b0b11e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 275;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/faf7979e64fd5918-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 275;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/8653040e4cb5d341-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/1af17ec9a5691cd2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/5bcbc39962ba4aa1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/d0241e99dc64cb0d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/3df66d77ecee2add-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/e342aa841617ab1b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/af0fb1e79086a3c8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/6f5eb9bb80ed5438-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/d411c38537ba1894-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
ascent-override: 95%;
font-family: '__fontMono_fad6d1';
src: url(/_next/static/media/da13a401b4771b0b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__fontMono_Fallback_fad6d1';src: local("Arial");ascent-override: 80.18%;descent-override: 13.69%;line-gap-override: 0.00%;size-adjust: 139.46%
}.__className_fad6d1 {font-family: '__fontMono_fad6d1', '__fontMono_Fallback_fad6d1'
}.__variable_fad6d1 {--font-input-mono: '__fontMono_fad6d1', '__fontMono_Fallback_fad6d1'
}

